import { Box, Typography } from "@mui/material";

export default function AboutUs(){

    return(
        <div>
        <Box sx={{ bgcolor: 'primary.main', 
            flexGrow: 1, border: 0.05, 
            height: '0.01rem', width: 'auto'}} />

        
            <Box sx={{m:3}}>
                <Typography variant="h5">
                    About Us:
                </Typography>
                <p>
                </p>

                <Typography variant="body2">
                    Employability of a student candidate passing out of the college, has been one of the greater challenges in the industry. We are trying to address this by focusing only on improving the employability of a trainee with us. We target to achieve this, by providing an environment similar to a typical Professional Software Engineer’s day in work life.
                    <p>
                        We are part of Digilytics Systems LLP. We offer only the training cum (Real) Internship programs for a student candidates across Engineering and Science courses (and also to passionate computer programmers). Our Mentors / Coaches, not only, help the student candidates by providing a close guidance during training parts, but also, endavor to create exploratory and experiential learning opportunities during these Internship engagements.
                    </p>
                    <p>
                        We orient our student candidates on Software Engineering/Technology, Project Management and Agile Methodologies, to help them prepare, to face the Professional World from the Day 1 of their working life.
                    </p>
                    <p>
                        We had already been engaged with 100+ Internship students, across various engineering / MCA / MTech programs, in past few years. We have been primarily associated with various renowned institutions based out of Pune / Nagpur like VIIT, MIT, PCCoE, G.H. Raisoni and Zeal Engg, through these internship programs over these years.
                    </p>
                </Typography>

            </Box>
        </div>
    );
}