
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import techStackImg from '../resources/FullStackTech.png'
import { Box, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import qkStDtls from "../resources/QkStBro.jpg"
import hdStDtls from "../resources/HdStBro.jpg"
import { useNavigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';

let linkCpy = "No URL to copy";

export default function ServCard({ prgm }) {

  const [openQkDtls, setOpenQkDtls] = React.useState(false);
  const [openHdDtls, setOpenHdDtls] = React.useState(false);
  const [shareURL, setShareURL] = React.useState(false);

  const navigateTo = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function onClickShare() {
    //alert(window.location.href);
    linkCpy = window.location.href;
    setShareURL(true)
    // console.log(linkCpy);
    //shareableLink(linkCpy);
  }

  function onClickMore() {

    // if (prgm.type === "quickstart") {
    //   setOpenQkDtls(true);
    // }
    // else {
    //   // alert("NOT in Quickstart More")
    //   setOpenHdDtls(true);
    // }
    navigateTo('learnMore');
  }

  function onClickRegister() {

    window.open("https://forms.gle/DTDgQWNVknUepLyn9");
  }

  const handleQkClose = () => setOpenQkDtls(false);
  const handleHdClose = () => setOpenHdDtls(false);
  const handleShareLnkClose = () => setShareURL(false);


  return (
    <div justifyContent='center' alignItems='center'>
      <Card sx={{ maxWidth: 500, border: 0.5, borderColor: "lightgray",
              justifyContent: "center",
              alignItems: "center", }}>
        <Box sx={{
          height: 100, maxHeight: 151,
          justifyContent: "center",
          alignItems: "center",

          border: 0.5, borderColor: "lightblue",
          display: "flex"
        }}>
          <img src={techStackImg} alt='TechStack'/>
        </Box>

        <CardContent>

          {/* below javascript is little bit of overkill but it's purpose here is to prove
            inline condition check without if-else under JSX */}
          {
            prgm.type === "quickstart"
              ?
              <>
                <Typography gutterBottom variant="h5" component="div">
                  <h3 style={{ color: 'blue' }}>Quickstart Live Training & Internship Programs <sup>*</sup></h3>
                </Typography>
                <Typography variant="body2">
                  <h3> We offer:</h3>
                  <ul>
                    <li>
                      <b>Quickstart Live Training</b> & Internship Programs
                    </li>
                    <p></p>
                    <li>
                      <b>Free Online Internship</b> in Product / Project Development
                    </li>
                    <p></p>
                    <li>
                      Training & Internship completion Certificate at the end of the program
                    </li>
                    <p></p>
                    <li>
                      Money back option
                    </li>
                    <br/>
                    <li>
                      Placement Assistance
                    </li>
                  </ul>
                </Typography>

                <Typography variant="body2">
                  <h3> <u>Eligibility</u></h3>
                  <ul>
                    <li>
                      B.E. / BTech / MTech (pursuing or completed)
                    </li>
                    <br/>
                    <li>
                      MCA / BCA / MBA-Systems(pursuing or completed)
                    </li>
                    <br/>
                    <li>
                      BSc / MSc (pursuing or completed)
                    </li>
                    <br/>
                    <li>
                      Diploma in Computer Engineering
                    </li>
                    <br/>
                    <li>
                      Any under / post graduate who is passionate about Software Engineering and has some background on Mathematics.
                    </li>
                    <br/>
                  </ul>
                </Typography>
              </>
              :
              <>
                <Typography gutterBottom variant="h5" component="div" color="text.primary">
                  <h3 style={{ color: 'red' }}>HeadStart Fullstack Training Program<sup>*</sup></h3>
                </Typography>
                <Typography variant="body2">
                  <h3> What is in it for me?</h3>
                  <ul>
                    <li>
                      <b>Technology Training</b> (Online) on full/multi stack (Duration: 3 Weeks @ 2 Hrs per day)
                    </li>
                    <p></p>
                    <li>
                      <b>Free Internship</b> (Online) on Product / Project Development (<b>Duration: 4 Months</b> / 320 Hrs)
                    </li>
                    <p></p>
                    <li>
                      Training & Internship completion Certificate on conclusion
                    </li>
                  </ul>
                </Typography>

                <Typography variant="body2">
                  <h3> <u>Eligibility</u></h3>
                  <ul>
                    <li>
                      B.E. / BTech / MTech (pursuing or completed)
                    </li>
                    <li>
                      MCA / BCA / MBA-Systems(pursuing or completed)
                    </li>
                    <li>
                      BSc / MSc (pursuing or completed)
                    </li>
                    <li>
                      Diploma in Computer Engineering
                    </li>
                    <li>
                      Any under / post graduate who is passionate about Software Engineering and has some background on Mathematics.
                    </li>
                  </ul>
                </Typography>

              </>
          }
        </CardContent>

        <CardActions>
          <Button size="small" variant='contained' onClick={onClickShare}><ShareIcon/></Button>
          <Button size="small" variant='contained' onClick={onClickMore}>Learn More</Button>
          <Button size="small" variant='contained' onClick={(onClickRegister)}>Register</Button>
        </CardActions>
      </Card>

      <Modal
        open={openQkDtls}
        onClose={handleQkClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="img" src={qkStDtls} />

      </Modal>

      <Modal
        open={openHdDtls}
        onClose={handleHdClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="img" src={hdStDtls} />
      </Modal>

      <Modal
        open={shareURL}
        onClose={handleShareLnkClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant='h6'>{linkCpy}</Typography>
          <div></div>

          <div>
            <Stack direction="row" spacing={2} sx={{ m: 3 }}>
              <Button variant='contained'
                onClick={() => { navigator.clipboard.writeText(linkCpy) }}> Copy</Button>

              <Button variant='contained'
                onClick={() => handleShareLnkClose()}> Close</Button>
            </Stack>
          </div>

        </Box>

      </Modal>

    </div>
  );
}