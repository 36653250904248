import * as React from 'react';
//import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
//import WhatsAppIcon from "../resources/whatsapp-svgrepo-com.svg" ;
import {ReactComponent as WhatsAppReactLogo} from "../resources/whatsapp-svgrepo-com.svg";
import { IconButton, Typography } from '@mui/material';
//import { Link } from 'react-router-dom';
import { LinkedIn } from '@mui/icons-material';



export default function PageFooter() {

    //const footNavi = useNavigate(); // used for handling the routes

    return (
    <>
        <Box sx={{ flexGrow: 1 }}>

        {/* draw a header/footer line component */}
        <Box sx={{ bgcolor: 'primary.main', 
            flexGrow: 1, border: 1, 
            height: '0.1rem', width: 'auto'}} />
        <br />
        
          <Grid container spacing={2} minHeight={160}>
            
            <Grid xs display="flex" justifyContent="center" >
                <div>
                        <Box><b>Contact Us:</b></Box>
                        <p>
                            <Typography>
                                <b>Email Id:</b> <u>agiletatva@gmail.com</u>
                            </Typography>
                            <Typography>
                            <b>Contact Number:</b> 9422652285
                            </Typography>
                        </p>
                </div>
            </Grid>
            <Grid xs display="flex" justifyContent="center" >
                <div>
                         {/* open the TnC page in browser window using target option*/}
                        {/* <Box><b><Link to='/TnC' target='_blank'>   Terms and Privacy Policy</Link></b></Box> */}
                        
                </div>
            </Grid>
            <Grid xs display="flex" justifyContent="center" >
                <div>
                    <Box><b>Social Media:</b></Box>
                    <Stack direction="row" >
                        <IconButton href="https://www.linkedin.com/" target="_blank">
                            <LinkedIn color={'primary'} fontSize={'large'}/>

                        </IconButton>
{/* 
                        <IconButton href="https://www.youtube.com/@PuneManagementAssociation">
                            <YouTubeIcon color={'error'} fontSize={'large'} />
                        </IconButton> */}
                    </Stack>
                        
                    <Stack direction="row" >

                        {/* <IconButton href="https://www.facebook.com/groups/PuneManagement">
                            <FacebookIcon color={'primary'} fontSize={'large'}/>
                        </IconButton> */}

                        <IconButton href="https://wa.me/9422652285">
                            <WhatsAppReactLogo/>
                        </IconButton>

                        

                    </Stack>
                </div>
            </Grid>
          </Grid>

        
          {/* draw a header/footer line component */}
        <Box sx={{ bgcolor: 'primary.main', 
            flexGrow: 1, border: 1, 
            height: '0.1rem', width: 'auto'}} />
            
        </Box>
        <br/>
        <div>
        {/* <Typography fontSize={12} sx={{ml:5}}>* - T & C Apply</Typography>
            <Typography fontSize={12} sx={{ml:5}}>Copyright @ 2023-2024 - maps<sup>2</sup>.in</Typography> */}
            <Typography variant="caption" m={5}>
                <sup>*</sup> - T&C apply
                <i>Applicable Govt Taxes, if any, will be extra.</i></Typography>
        </div>
        <br/>
    </>
    );
}