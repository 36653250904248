// //import './App.css';
import { Box } from '@mui/material';
import NavBar from './components/NavBar';
import PageFooter from './components/PageFooter';
import AboutUs from './pages/AboutUs';
import HomePg from './pages/HomePg';
import { Route, Routes } from 'react-router-dom';
import LoginPg from './pages/LoginPg';
import LearnMorePg from './pages/LearnMorePg';


function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

    
      <>
          <NavBar/>
        {/* Adding browser routing paths */}
        <Routes>
            <Route path="/" element={<HomePg/>} />
              <Route index element={<HomePg />} />
              <Route path="loginPg" element={<LoginPg />} />
              <Route path="learnMore" element={<LearnMorePg/>} />
          </Routes>
      {/* ended routes */}

          <PageFooter/>
        </>
  );
}

export default App;
