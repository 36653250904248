import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import maps2Logo from "../resources/maps2Learning.jpg"
import { Alert, Avatar, Button, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import LoginPg from '../pages/LoginPg';
import { useNavigate } from 'react-router-dom';
import { Login, Logout } from '@mui/icons-material';
import { auth } from '../firebase';

// this is an application Nav Bar

export default function NavBar() {

  const [startLogIn, setStartLogIn] = React.useState(false);
  const navigateTo = useNavigate();

  const handleLoginClick = () => {
    //alert("you have clicked on Login");
    sessionStorage.setItem("email", "")
    console.log("session store value: ", sessionStorage.getItem("email"));

    if (sessionStorage.getItem("email") == "") {
      console.log("Inside if");
      setStartLogIn(true);
      console.log(startLogIn);
      navigateTo('loginPg');
      setAnchorEl(null);
    }

  }

  const handleLogoutClick = () => {
    console.log ("User is logged out");
    auth.signOut();  
    sessionStorage.setItem("email", ""); 
    setStartLogIn(true); 
    setAnchorEl(null);
    navigateTo('/')
  }

  // Menu related code
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Box sx={{Height:3, Width:5, mr:3}}>
            <img src={maps2Logo} alt='TechStack'/>
          </Box> */}
          {/* <Button flexGrow={1} variant='text' onClick={
            () => {
                navigateTo("/");
            }
          }> */}
          
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, justifyContent: 'center' }}>
              {/* <Button variant='text'
                onClick={()=> {navigateTo('/')}}> */}
                  <b>maps<sup>2</sup> Learnings</b> 
              {/* {</Button> */}
            </Typography>
          
          {/* </Button> */}

          {/* <Button color="inherit">Login</Button> */}

          {/* <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
          >

            <Avatar />
          </IconButton> */}

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
          // onClick={handleClose}
          // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleLoginClick}>
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              Login
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>


        </Toolbar>
      </AppBar>
    </Box>
  );
}