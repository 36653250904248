import * as React from 'react';
import { Button } from "@mui/material";
import { auth, provider } from "../firebase";
import {signInWithPopup} from "firebase/auth";
import { useEffect } from "react";
import LearnMorePg from "../pages/LearnMorePg";


export default function GoogleSignIn(){

    const [usrLoginVal, setUserLoginVal] = React.useState("");

    const handleClick = ()=> {
        signInWithPopup(auth, provider).then((data)=> {
            setUserLoginVal(data.user.email);
            sessionStorage.setItem("email", data.user.email);
        });
    };

    useEffect( () => {
        const storedEmail = sessionStorage.getItem("email");

        if (storedEmail){
            setUserLoginVal(storedEmail);
        }
    }, []);


    return(
        <div>
            {
                usrLoginVal ? <LearnMorePg/>
                : <Button variant="contained" onClick={handleClick}>
                    Sign-In with Google</Button>
            }

        </div>
    );
}