import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ServCard from "../components/ServCard";
import { Box } from "@mui/material";
import AboutUs from "./AboutUs";

export default function HomePg(){
    return(
        <div>
            <Box sx={{m:5, flexGrow: 1}}>
            <Grid2 container spacing={4}>
                <Grid2 xs={6} md={6}>
                    <ServCard 
                    prgm = {{ type:"quickstart"}}
                    />
                </Grid2>

                {/* <Grid2 xs={6} md={6}>
                    <ServCard prgm={{type:"headstart"}} />
                </Grid2> */}

            </Grid2>
            </Box>

            <AboutUs/>
        </div>
    );

}