import { Box } from "@mui/material";
import GoogleSignIn from "../components/GoogleSignIn";

export default function LoginPg() {

    return(
        <div>
            <Box flexGrow={1} sx={{m:30, height:300, 
                alignItems:"center"}}>
                <GoogleSignIn />
                {/* On the login page now */}
            </Box>
       </div>
    );


}