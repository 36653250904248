import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import qkStDtls from "../resources/QkStBro.jpg"
//import hdStDtls from "../resources/HdStBro.jpg"
import { useNavigate } from "react-router-dom";
import OptionPaper from "../components/OptionPaper";
import HomeIcon from '@mui/icons-material/Home';


export default function LearnMorePg(){

    const navigateTo = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    return(
        <div>
            <Button variant="contained" sx={{m:3, bgcolor:"secondary.main"}} 
            onClick={()=>{navigateTo('/')}}> 
            
                <HomeIcon/>
            
            </Button>
            {/* <b>&lt;&lt;&nbsp;Home </b> </Button> */}
            <br/>
            <Divider></Divider>
            {/* <h1 ml={5}>Details about the Programs</h1> */}
            <Typography variant="h5" ml={5}>
                <b>Details about the Programs</b>
            </Typography>
            <p></p>
            {/* <h3><b>Headstart Training and Internship Program</b></h3> */}
            {/* <Box component="img" src={hdStDtls}
                width="70%" 
                ml={5}/>
            <br/>
            <br/>
            <Divider></Divider>
            <Divider></Divider>
            <br/>
            <br/> */}
            {/* <h3><b>Quickstart Training and Internship Program</b></h3> */}
            <Box component="img" src={qkStDtls} 
                width="70%"
                ml={5}/>   
    
            <br/>
            <Divider></Divider>
            <br/>
            {/* <Box m={5}>
                <Typography variant="h6" >Fees for these extensive trainings are:</Typography>
                <Typography variant="body2">- Headstart Training Program: INR 17000 Only</Typography>
                <Typography variant="body2">- Quickstart Training Program: INR 8500 Only</Typography>
                <Typography variant="body2">- Please call us for attractive Group discounts and Individual scholarship enquiries</Typography>
                <Typography variant="caption"><i>Applicable Govt Taxes, if any, will be extras</i></Typography>
            </Box> */}
            <OptionPaper/>
            <Box m={5}>
                <Typography variant="caption"><u><b>Moneyback Option:</b></u></Typography>
                <br/>
                <Typography variant="caption">
                    Get your money refunded if you prefer to opt out of the program 
                    anytime, only, during initial Training period of 2 calendar weeks 
                    from the start date. In such case of cancellation by you, we'll 
                    refud you the program fees after deducting the admin charges 
                    amouting to INR 2000 only. We'll not be issuing any training 
                    / Internship participation certificate in this case. Internship 
                    will not start without successful completion of training.
                </Typography>
                <br/>
            </Box>
            <br/>
            
            
                   
        </div>
    );
}