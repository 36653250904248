import { Button, Paper, Stack, Typography } from "@mui/material";

export default function OptionPaper(){

    return(
        <>
            <Stack direction="row" spacing={2} m={5} >
                <Paper elevation={3} sx={{bgcolor:'#ffecb3', p:1, width: '25%'}}>
                    <p><b>Course Code : 24QS2W4I</b></p>
                    <p>Live Training Duration: 2 Weeks</p>
                    <p>Internship Duration: 4 Weeks</p>
                    <p>Training Fees <sup>*</sup>: INR 3995 Only</p>
                    <p>Certificates: Training and Internship</p>
                    <Button 
                    size="small" 
                    variant="contained"
                    onClick={
                        ()=> window.open("https://forms.gle/DTDgQWNVknUepLyn9")}
                    >Register</Button>
                    </Paper>

                <Paper elevation={3} sx={{bgcolor:'#b2dfdb', p:1, width: '25%'}}>
                    <p><b>Course Code : 24QS2W6I</b></p>
                    <p>Live Training Duration: 2 Weeks</p>
                    <p>Internship Duration: 6 Weeks</p>
                    <p>Training Fees<sup>*</sup>: INR 5995 Only</p>
                    <p>Certificates: Training and Internship</p>
                    <Button 
                    size="small" 
                    variant="contained"
                    onClick={
                        ()=> window.open("https://forms.gle/DTDgQWNVknUepLyn9")}
                    >Register</Button>
                </Paper>

                <Paper elevation={3} sx={{bgcolor:'#bbdefb', p:1, width: '25%'}}>
                    <p><b>Course Code : 24QS2W12I</b></p>
                    <p>Live Training Duration: 2 Weeks</p>
                    <p>Internship Duration: 12 Weeks</p>
                    <p>Training Fees<sup>*</sup>: INR 11995 Only</p>
                    <p>Certificates: Training and Internship</p>
                    <Button 
                    size="small" 
                    variant="contained"
                    onClick={
                        ()=> window.open("https://forms.gle/DTDgQWNVknUepLyn9")}
                    >Register</Button>
                </Paper>

                <Paper elevation={3} sx={{bgcolor:'#e1bee7', p:1, width: '25%'}}>
                    <p><b>Course Code : 24QS2W16I</b></p>
                    <p>Live Training Duration: 2 Weeks</p>
                    <p>Internship Duration: 16 Weeks</p>
                    <p>Training Fees<sup>*</sup>: INR 18995 Only</p>
                    <p>Certificates: Training and Internship</p>
                    <p>Stipend: INR 2500 per month for 2nd, 3rd and 4th month of Internship</p>
                    <Button 
                    size="small" 
                    variant="contained"
                    onClick={
                        ()=> window.open("https://forms.gle/DTDgQWNVknUepLyn9")}
                    >Register</Button>
                </Paper>
                
            </Stack>
        </>
    );

}